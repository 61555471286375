<template>
  <div>
    <div class="text-center mx-auto">
      <div class="text-gray-300 text-sm">Carregando</div>
      <div class="text-gray-700 text-2xl">Revele seus momentos</div>
    </div>
  </div>
</template>

<script>
import serviceInstagram from "@/service/instagram";
// import { INSTAGRAM_ID } from "@/constants/instagram";
// import { baseURL } from "@/constants/base";
export default {
  data() {
    return {
      code: null,
    };
  },
  methods: {
    async requestAPI(code) {
      // const requestBody = {
      //   // client_id: INSTAGRAM_ID,
      //   redirect: `${baseURL}user/auth-instagram/`,
      //   code: code,
      // };
      localStorage.setItem("ig-code", code);
      await serviceInstagram
        .requestAccess(code)
        .then((resp) => {
          let recebido = resp;
          console.log({ resp });
          localStorage.setItem("ig-token", recebido.access_token);
          localStorage.setItem("ig-userId", recebido.user_id);
          this.$router.push("/user/fotos-instagram");
        })
        .catch((err) => {
          console.log("Erro RequestAccess", err);
          this.$router.push("/user/seleciona-metodo");
        });
    },
  },
  beforeMount() {
    const tempCode = this.$route.query.code;
    if (tempCode) {
      var queryCode = tempCode.replace("#_");
      this.requestAPI(queryCode);
    } else {
      console.error("ERRO 01", "ERROR -- sem query");
      this.$router.push("/user/seleciona-metodo");
    }
  },
};
</script>

<style></style>
